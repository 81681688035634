
import { defineComponent, computed, ref, reactive, onMounted } from "vue";
import { useForm, Field, ErrorMessage } from "vee-validate";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import SurveyService from "@/core/services/SurveyService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { translate, translateKey } from "@/hooks/TranslateService";
import { InputTypes } from "@/store/enums/StoreEnums";

import IntegerGenderField from "@/components/wizard/steps/input_fields/IntegerGenderField.vue";
import SelectYesNoField from "@/components/wizard/steps/input_fields/SelectYesNoField.vue";
import SurveyConfirmModal from "@/components/wizard/SurveyConfirmModal.vue";
import { errorMessage } from "@/core/services/AlertService";

interface IBackEndError {
  error: string;
  error_en: string;
}

interface IInputArr {
  auto_number: number;
  value: any;
}

export default defineComponent({
  name: "survey-step",
  props: [
    "head_line",
    "input_values_reduce",
    "input_values_in_step",
    "survey_obj",
    "step",
    "errors",
    "totalSteps",
    "isActiveStep",
    "activeStepId",
  ],
  components: {
    SelectYesNoField,
    IntegerGenderField,
    SurveyConfirmModal,
    Field,
    ErrorMessage,
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    //START REF VALUES

    const submitButton = ref<HTMLElement | null>(null);
    const surveyConfirmModalRef =
      ref<InstanceType<typeof SurveyConfirmModal>>();
    const backendErrorMessage = ref<IBackEndError>({
      error: "",
      error_en: "",
    });

    const messages_survey = [
      "SURVEY_1_MESSAGE_COMPOSIZIONE_DIPENDENTI",
      "SURVEY_2_3_MESSAGE_COMPOSIZIONE_CDA",
      "SURVEY_2_3_MESSAGE_COMPOSIZIONE_CDA",
      "SURVEY_4_MESSAGE_COMPOSIZIONE_TOP_MANAGEMENT",
      "SURVEY_5_MESSAGE_SUCCESSION_PLAN",
      "SURVEY_6_MESSAGE_COMPOSIZIONE_NUOVI_DIPENDENTI",
      "SURVEY_7_MESSAGE_TURNOVER_DIPENDENTI",
      "SURVEY_8_MESSAGE_COMPOSIZIONE_DIPENDENTI",
      "SURVEY_9_MESSAGE_CONGEDO_PARENTALE",
      "SURVEY_10_MESSAGE_AVANZAMENTO_CARRIERA",
      "SURVEY_11_MESSAGE_DIFFERENZE_RETRIBUTIVE",
      "SURVEY_12_MESSAGE_FORME_DI_LAVORO_FLESSIBILE",
    ];
    // END REF VALUES

    // START COMPUTED VALUES
    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));
    const hasErrorsInStep = ref<Record<string, boolean>>({});

    const currentFormData = computed(() => {
      return SurveyService.getSchemaByInput(
        parseInt(route.params.step as string),
        props.input_values_in_step,
        t
      );
    });

    const setsHeaderList = reactive(
      computed(() => store.getters.getSurveySetsHeaderList)
    );

    const currentUser = reactive(computed(() => store.getters.currentUser));

    // END COMPUTED VALUES

    const { values, handleSubmit } = useForm<Record<string, unknown>>({
      validationSchema: currentFormData,
    });

    // START METHODS

    const submitSurveyInput = () => {
      if (!values) {
        return false;
      }
      let inputArr: IInputArr[] = [];
      Object.keys(values).map((key) => {
        inputArr.push({
          auto_number: parseInt(key.replace("input", "") as string),
          value: values[key],
        });
      });
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store
        .dispatch(Actions.PUT_SURVEY_INPUT_LIST, {
          data: inputArr,
          id: route.params.id ? route.params.id : "",
          force: Boolean(hasErrorsInStep.value[route.params.step as string])
        })
        .then(() => {
          // if (route.params.step == "12") {
          //   store.dispatch(Actions.REQ_COMPANY_LIST_YEAR);
          // }
          window.scrollTo({ top: 0, behavior: "smooth" });
          store.dispatch(Actions.REQ_SURVEY_INPUT_LIST, isAdmin.value ? route.params.id : "").then(() => {
            store.dispatch(Actions.REMOVE_SURVEY_ERROR);
            hasErrorsInStep.value = { [route.params.step as string]: false };
            if (parseInt(route.params.step as string) != props.totalSteps) {
              if (isAdmin.value) {
                store.dispatch(
                  Actions.REQ_III_BULLETS, 
                  { 
                    "user_id": route.params.id,
                    "year": localStorage.getItem("to_year_admin")
                  }
                );
                router.push({
                  name: "AdminSurvey",
                  params: { step: parseInt(route.params.step as string) + 1 },
                });
              } else {
                store.dispatch(
                  Actions.REQ_III_BULLETS, 
                  { 
                    "year": localStorage.getItem("to_year")
                  }
                );
                router.push({
                  name: "survey-iii",
                  params: { step: parseInt(route.params.step as string) + 1 },
                });
              }
            } else {
              if (isAdmin.value) {
                router.push({
                  path: `/admin/report/${route.params.id}`
                });
              } else {
                router.push({
                  name: "report",
                });
              }
            }
          });
        })
        .catch(() => {
          backendErrorMessage.value = {
            error:
              "Abbiamo trovato un errore durante la fase di compilazione del form.",
            error_en: "We found an error during form editing.",
          };
          hasErrorsInStep.value = { [route.params.step as string]: true };
          errorMessage(translate("SURVEY_ERROR_MESSAGE"), "Ok");
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const toogleConfirmModal = () => {
      surveyConfirmModalRef.value?.onShowModal();
    };

    const checkSurveyInput = async (values: any) => {
      if (!values) {
        return false;
      }
      let inputArr: IInputArr[] = [];
      Object.keys(values).map((key) => {
        inputArr.push({
          auto_number: parseInt(key.replace("input", "") as string),
          value: values[key],
        });
      });
      await store
        .dispatch(Actions.CHECK_SURVEY_INPUT_LIST, {
          data: inputArr,
          id: route.params.id ? route.params.id : "",
        })
        .then(() => {
          store.dispatch(Actions.PURGE_SURVEY_WARNING);
          submitSurveyInput();
        })
        .catch(() => {
          toogleConfirmModal();
        });
    };

    /**
     * Handle Step
     * @param values
     */
    const handleStep = handleSubmit((values) => {
      backendErrorMessage.value = {
        error: "",
        error_en: "",
      };
      checkSurveyInput(values);
    });

    const previousStep = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (isAdmin.value) {
        router.push({
          name: "AdminSurvey",
          params: { step: parseInt(route.params.step as string) - 1 },
        });
      } else {
        router.push({
          name: "survey-iii",
          params: { step: parseInt(route.params.step as string) - 1 },
        });
      }

      backendErrorMessage.value = {
        error: "",
        error_en: "",
      };
    };

    const navigatePage = (page) => {
      if (isAdmin.value) {
        router.push({
          name: "AdminSurvey",
          params: { step: page },
        });
      } else {
        router.push({
          name: "survey-iii",
          params: { step: page },
        });
      }
    };

    const nextStep = () => {
      if (parseInt(route.params.step as string) != props.totalSteps) {
        const toStep = parseInt(route.params.step as string) + 1;
        if (toStep > props.activeStepId) {
          Swal.fire({
            text: t("DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_CONTENT"),
            icon: "warning",
            cancelButtonText: t("DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_BACK"),
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
              cancelButton: "btn fw-bold btn-outline",
            },
          }).then(({ isConfirmed }) => {
            if (isConfirmed) {
              navigatePage(toStep);
            }
          });
        } else {
          navigatePage(toStep);
        }
      } else {
        Swal.fire({
          text: t("DIALOG_WARNING_UNFINISHED_SURVEY_CONTENT"),
          icon: "warning",
          cancelButtonText: t("DIALOG_WARNING_UNFINISHED_SURVEY_BACK"),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
            cancelButton: "btn fw-bold btn-outline",
          },
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            router.push({
              name: "dashboard",
            });
          }
        });
      }

      backendErrorMessage.value = {
        error: "",
        error_en: "",
      };
    };

    const getErrors = (id, set_id) => {
      if (props.errors)
        return props.errors.filter(
          (item) => item.id == id && item.set_id == set_id
        );
      else return null;
    };

    return {
      handleStep,
      previousStep,
      nextStep,
      translate,
      translateKey,
      messages_survey,
      SurveyService,
      InputTypes,
      setsHeaderList,
      currentUser,
      getErrors,
      backendErrorMessage,
      submitButton,
      surveyConfirmModalRef,
      submitSurveyInput
    };
  },
});
