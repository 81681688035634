import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-2" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "col-lg-3" }
const _hoisted_4 = { class: "fv-plugins-message-container" }
const _hoisted_5 = { class: "fv-help-block" }
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "col-lg-3" }
const _hoisted_8 = { class: "fv-plugins-message-container" }
const _hoisted_9 = { class: "fv-help-block" }
const _hoisted_10 = ["for"]
const _hoisted_11 = { class: "col-3 col-lg-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        class: "col-lg-1 col-form-label fs-6 text-dark-blue fw-normal",
        for: 'woman_integer_' + _ctx.input.kpi.auto_number
      }, _toDisplayString(_ctx.translate("SURVEY_WOMEN_STRING")), 9, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(_component_Field, {
          type: "number",
          min: "0",
          name: 
            'input' +
            _ctx.SurveyService.getSingleInputIntegerGenderField(
              _ctx.input.input.set_id,
              _ctx.input.input.id,
              _ctx.InputTypes.INTEGER_WOMEN_SUB_ID,
              _ctx.input_values_in_step
            ).kpi.auto_number
          ,
          disabled: !_ctx.isActiveStep,
          key: 'woman_integer_' + _ctx.input.kpi.auto_number,
          id: 'woman_integer_' + _ctx.input.kpi.auto_number,
          class: "form-control form-control-lg value-existed text-dark-blue fw-normal",
          modelValue: _ctx.getWomenValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getWomenValue) = $event))
        }, null, 8, ["name", "disabled", "id", "modelValue"])),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ErrorMessage, {
              name: 
                'input' +
                _ctx.SurveyService.getSingleInputIntegerGenderField(
                  _ctx.input.input.set_id,
                  _ctx.input.input.id,
                  _ctx.InputTypes.INTEGER_WOMEN_SUB_ID,
                  _ctx.input_values_in_step
                ).kpi.auto_number
              
            }, null, 8, ["name"])
          ])
        ])
      ]),
      _createElementVNode("label", {
        class: "col-lg-1 col-form-label fs-6 text-dark-blue fw-normal",
        for: 'man_integer_' + _ctx.input.kpi.auto_number
      }, _toDisplayString(_ctx.translate("SURVEY_MEN_STRING")), 9, _hoisted_6),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(), _createBlock(_component_Field, {
          type: "number",
          disabled: !_ctx.isActiveStep,
          key: 'man_integer_' + _ctx.input.kpi.auto_number,
          id: 'man_integer_' + _ctx.input.kpi.auto_number,
          min: "0",
          name: 
            'input' +
            _ctx.SurveyService.getSingleInputIntegerGenderField(
              _ctx.input.input.set_id,
              _ctx.input.input.id,
              _ctx.InputTypes.INTEGER_MEN_SUB_ID,
              _ctx.input_values_in_step
            ).kpi.auto_number
          ,
          class: "form-control form-control-lg text-dark-blue fw-normal",
          modelValue: _ctx.getMenValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getMenValue) = $event))
        }, null, 8, ["disabled", "id", "name", "modelValue"])),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_ErrorMessage, {
              name: 
                'input' +
                _ctx.SurveyService.getSingleInputIntegerGenderField(
                  _ctx.input.input.set_id,
                  _ctx.input.input.id,
                  _ctx.InputTypes.INTEGER_MEN_SUB_ID,
                  _ctx.input_values_in_step
                ).kpi.auto_number
              
            }, null, 8, ["name"])
          ])
        ])
      ]),
      _createElementVNode("label", {
        class: "col-1 col-lg-1 col-form-label fs-6 text-dark-blue fw-normal",
        for: 'total_integer_' + _ctx.input.kpi.auto_number
      }, _toDisplayString(_ctx.translate("SURVEY_TOTAL_STRING")), 9, _hoisted_10),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(), _createBlock(_component_Field, {
          type: "number",
          readonly: "",
          disabled: "",
          key: 'total_integer_' + _ctx.input.kpi.auto_number,
          id: 'total_integer_' + _ctx.input.kpi.auto_number,
          name: 
            'input' +
            _ctx.SurveyService.getSingleInputIntegerGenderField(
              _ctx.input.input.set_id,
              _ctx.input.input.id,
              _ctx.InputTypes.INTEGER_TOTAL_SUB_ID,
              _ctx.input_values_in_step
            ).kpi.auto_number
          ,
          class: "form-control form-control-lg border-0 total__input text-dark-blue fw-normal",
          modelValue: _ctx.total,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.total) = $event))
        }, null, 8, ["id", "name", "modelValue"]))
      ])
    ])
  ]))
}