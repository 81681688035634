import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-2" }
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = { class: "form-check form-check-inline form-check-solid me-5" }
const _hoisted_4 = ["value", "disabled"]
const _hoisted_5 = { class: "fw-bold ps-2 fs-6" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "form-check form-check-inline form-check-solid me-5" }
const _hoisted_8 = ["value", "disabled"]
const _hoisted_9 = { class: "fw-bold ps-2 fs-6" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = { class: "fv-plugins-message-container" }
const _hoisted_12 = { class: "fv-help-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Field, {
          class: "form-check-input d-none",
          type: "text",
          name: 'input' + _ctx.input.kpi.auto_number,
          modelValue: _ctx.theInput.input.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.theInput.input.value) = $event))
        }, null, 8, ["name", "modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              type: "radio",
              value: _ctx.InputTypes.VALUE_YES_FIELD,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.theInput.input.value) = $event)),
              disabled: !_ctx.isActiveStep
            }, null, 8, _hoisted_4), [
              [_vModelRadio, _ctx.theInput.input.value]
            ]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate("SURVEY_YES_STRING")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              type: "radio",
              value: _ctx.InputTypes.VALUE_NO_FIELD,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.theInput.input.value) = $event)),
              disabled: !_ctx.isActiveStep
            }, null, 8, _hoisted_8), [
              [_vModelRadio, _ctx.theInput.input.value]
            ]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.translate("SURVEY_NO_STRING")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("i", {
            class: "fas fa-eraser ms-1 fs-2",
            "data-bs-toggle": "tooltip",
            title: "Info",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.theInput.input.value = ''))
          })
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_ErrorMessage, {
              name: 'input' + _ctx.input.kpi.auto_number
            }, null, 8, ["name"])
          ])
        ])
      ])
    ])
  ]))
}