
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch
} from "vue";
import SurveyStep from "@/components/wizard/steps/SurveyStep.vue";
import SurveryStructure from "@/views/divere/pages/survey-structure/SurveryStructure.vue";
import SurverySummary from "@/views/divere/pages/survey-summary/SurverySummary.vue";
import { IIIInterface } from "@/store/modules/valored/IIIModule";
import { StepperComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter, useRoute } from "vue-router";
import SurveyService from "@/core/services/SurveyService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { translate, translateKey } from "@/hooks/TranslateService";
import Vue3Html2pdf from "vue3-html2pdf";
import { ICompanyYear } from "@/store/modules/valored/CompanyModule";
import { useI18n } from "vue-i18n";

interface IBackEndError {
  error: string;
  error_en: string;
}

export default defineComponent({
  name: "kt-survey-iii",
  components: {
    SurveyStep,
    Vue3Html2pdf,
    SurveryStructure,
    SurverySummary,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const _stepperObj = ref<StepperComponent | null>(null);
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const html2Pdf = ref<null | HTMLFormElement>(null);
    const html2PdfSummary = ref<null | HTMLFormElement>(null);

    const surveyStructurDownloadLoading = ref<boolean>(false);
    const surveySummaryDownloadLoading = ref<boolean>(false);
    const pdfOptionsForSurveyStructure = ref({
      margin: [20, 15, 20, 15],
      html2canvas: {
        scrollX: 0,
        scrollY: 0
      }
    });

    const backendErrorMessage = ref<IBackEndError>({
      error: "",
      error_en: "",
    });

    const surveyStepList = reactive(
      computed(() => store.getters.getSurveyStepList)
    );

    const activeStepId = reactive(
      computed(() => store.getters.getActiveStepId)
    );

    const surveyInputList = reactive(
      computed(() => store.getters.getFilteredSurveyInputList)
    );

    const setsHeaderList = reactive(
      computed(() => store.getters.getSurveySetsHeaderList)
    );

    const errors = reactive(computed(() => store.getters.getSurveyErrors));

    const currentYear = reactive(computed(() => store.getters.getIII));

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );

    const optionYearlyPeriod = ref(
      computed(() => store.getters.getCompanyYearList as ICompanyYear)
    );

    const surveyObj = reactive(
      computed(
        () => new SurveyService(store.getters.getFilteredSurveyInputList)
      )
    );

    const getStepClass = (array, step) => {
      if      (step === parseInt(route.params.step as string)) return "current";
      else if (array[step - 1])                                return "completed";
      return "pending";
    };

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }
      return _stepperObj.value.totatStepsNumber;
    });

    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

    const getSurveyInputList = () => {
      if (isAdmin.value) {
        store.dispatch(Actions.REQ_SURVEY_INPUT_LIST, route.params.id);
        store.dispatch(
          Actions.REQ_III_BULLETS, 
          { 
            "user_id": route.params.id,
            "year": localStorage.getItem("to_year_admin")
          }
        );
      } else {
        store.dispatch(Actions.REQ_SURVEY_INPUT_LIST);
        store.dispatch(
          Actions.REQ_III_BULLETS, 
          { 
            "year": localStorage.getItem("to_year")
          }
        );
      }
    };

    const bullets = ref(
      computed(() => store.getters.getIIIBullets as Array<IIIInterface>)
    );

    onMounted(() => {
      setTimeout(() => {
        store.dispatch(
          Actions.CHANGE_CURRENT_YEARLY_PERIOD, 
          isAdmin.value ? localStorage.getItem("to_year_admin") : localStorage.getItem("to_year")
        )
      }, 1000);
      
      setTimeout(function () {
        _stepperObj.value = StepperComponent.createInsance(
          verticalWizardRef.value as HTMLElement
        );
      }, 3000);
      if (parseInt(route.params.step as string) !== 1) {
        if (isAdmin.value) {
          router.push({
            name: "AdminSurvey",
            params: { step: 1 },
          });
        } else {
          router.push({
            name: "survey-iii",
            params: { step: 1 },
          });
        }
      }
      setCurrentPageBreadcrumbs("Vertical", ["Pages", "Wizards"]);
      store.dispatch(Actions.REQ_SURVEY_STEP_LIST);
      store.dispatch(Actions.REQ_SURVEY_SETS_HEADER_LIST);

      getSurveyInputList();
    });

    const navigateStep = (to_step) => {
      if (to_step <= activeStepId.value) {
        if (isAdmin.value) {
          router.push({
            name: "AdminSurvey",
            params: { step: to_step },
          });
        } else {
          router.push({
            name: "survey-iii",
            params: { step: to_step },
          });
        }
      } else {
        Swal.fire({
          text: t("DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_CONTENT"),
          icon: "warning",
          cancelButtonText: t("DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_BACK"),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
            cancelButton: "btn fw-bold btn-outline",
          },
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            if (isAdmin.value) {
              router.push({
                name: "AdminSurvey",
                params: { step: to_step },
              });
            } else {
              router.push({
                name: "survey-iii",
                params: { step: to_step },
              });
            }
          }
        });
      }

      backendErrorMessage.value = {
        error: "",
        error_en: "",
      };
    };

    const nextStep = () => {
      if (parseInt(route.params.step as string) != totalSteps.value) {
        if (isAdmin.value) {
          router.push({
            name: "AdminSurvey",
            params: { step: parseInt(route.params.step as string) + 1 },
          });
        } else {
          router.push({
            name: "survey-iii",
            params: { step: parseInt(route.params.step as string) + 1 },
          });
        }
      } else {
        Swal.fire({
          text: t("DIALOG_WARNING_UNFINISHED_SURVEY_CONTENT"),
          icon: "warning",
          cancelButtonText: t("DIALOG_WARNING_UNFINISHED_SURVEY_BACK"),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
            cancelButton: "btn fw-bold btn-outline",
          },
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            router.push({
              name: "dashboard",
            });
          }
        });
      }

      backendErrorMessage.value = {
        error: "",
        error_en: "",
      };
    };

    const previousStep = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (isAdmin.value) {
        router.push({
          name: "AdminSurvey",
          params: { step: parseInt(route.params.step as string) - 1 },
        });
      } else {
        router.push({
          name: "survey-iii",
          params: { step: parseInt(route.params.step as string) - 1 },
        });
      }

      backendErrorMessage.value = {
        error: "",
        error_en: "",
      };
    };

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        window.location.reload();
      });
    };

    const printResult = () => {
      if (html2Pdf.value) {
        surveyStructurDownloadLoading.value = true;
        html2Pdf.value.resetPagination();
        html2Pdf.value.downloadPdf();
      }
    };

    const printReport = () => {
      if (html2PdfSummary.value) {
        surveySummaryDownloadLoading.value = true;
        html2PdfSummary.value.resetPagination();
        html2PdfSummary.value.downloadPdf();
      }
    };

    const onProgress = (event) => {
      if (event == 100) {
        surveyStructurDownloadLoading.value = false;
      }
    };

    const onPrintSummaryProgress = (event) => {
      if (event == 100) {
        surveySummaryDownloadLoading.value = false;
      }
    };

    watch(
      () => route.params.step,
      (step) => {
        if (!_stepperObj.value) {
          return;
        }
        _stepperObj.value.goto(parseInt(step as string));
        backendErrorMessage.value = {
          error: "",
          error_en: "",
        };
      }
    );

    watch([currentYear, current_yearly_period], () => {
      getSurveyInputList();
    });

    return {
      verticalWizardRef,
      previousStep,
      formSubmit,
      totalSteps,
      surveyObj,
      surveyStepList,
      surveyInputList,
      setsHeaderList,
      translate,
      translateKey,
      errors,
      backendErrorMessage,
      nextStep,
      submitButton,
      activeStepId,
      navigateStep,
      printResult,
      printReport,
      html2Pdf,
      html2PdfSummary,
      onProgress,
      onPrintSummaryProgress,
      surveyStructurDownloadLoading,
      surveySummaryDownloadLoading,
      pdfOptionsForSurveyStructure,
      bullets,
      getStepClass,
    };
  },
});
