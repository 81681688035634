
import { computed, defineComponent, onMounted, onUpdated, reactive } from "vue";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { translate, translateKey } from "@/hooks/TranslateService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "survey-structure",
  setup() {
    const messages_survey = [
      "SURVEY_1_MESSAGE_COMPOSIZIONE_DIPENDENTI",
      "SURVEY_2_3_MESSAGE_COMPOSIZIONE_CDA",
      "SURVEY_2_3_MESSAGE_COMPOSIZIONE_CDA",
      "SURVEY_4_MESSAGE_COMPOSIZIONE_TOP_MANAGEMENT",
      "SURVEY_5_MESSAGE_SUCCESSION_PLAN",
      "SURVEY_6_MESSAGE_COMPOSIZIONE_NUOVI_DIPENDENTI",
      "SURVEY_7_MESSAGE_TURNOVER_DIPENDENTI",
      "SURVEY_8_MESSAGE_COMPOSIZIONE_DIPENDENTI",
      "SURVEY_9_MESSAGE_CONGEDO_PARENTALE",
      "SURVEY_10_MESSAGE_AVANZAMENTO_CARRIERA",
      "SURVEY_11_MESSAGE_DIFFERENZE_RETRIBUTIVE",
      "SURVEY_12_MESSAGE_FORME_DI_LAVORO_FLESSIBILE",
    ];

    const getSurveyStructureList = reactive(
      computed(() => store.getters.getSurveyStructureList)
    );

    const surveyHeaderList = reactive(
      computed(() => store.getters.getSurveySetsHeaderList)
    );

    const surveyStepList = reactive(
      computed(() => store.getters.getSurveyStepList)
    );

    const getHeader = (auto_number) => {
      const result = surveyHeaderList.value.filter(
        (item) => item.after_kpi_number == auto_number
      );
      if (result.length > 0) {
        return result[0];
      } else return null;
    };

    const print = () => {
      window.print();
    };

    onMounted(() => {
      store.dispatch(Actions.REQ_SURVEY_STRUCTURE_LIST);
      store.dispatch(Actions.REQ_SURVEY_SETS_HEADER_LIST);
      store.dispatch(Actions.REQ_SURVEY_STEP_LIST);
      setCurrentPageBreadcrumbs("Survey Structure", ["Print", "Survey"]);
    });

    onUpdated(() => {
      checkPageHeight();
    });

    const checkPageHeight = () => {
      let height = 0;

      document.querySelectorAll("section[slot='pdf-content'][class='survey-structure']").forEach(pdfContent => {
        for (let i = 0; i < pdfContent.children.length; i++) {
          const child = pdfContent.children[i];

          if (
            child.clientHeight == undefined || 
            child.clientHeight == 0
          ) continue;

          if (height == 0) {
            child.querySelectorAll("div").forEach(div => {
              if (div.classList.contains("border-dark")) {
                div.classList.remove("border-dark");
              }
            });
          }

          (child as HTMLElement).style.transform = "scale(0.95)";

          if (height + child.clientHeight > 800) {
            let breakPageElement = document.createElement("div");
            breakPageElement.classList.add("html2pdf__page-break");
            breakPageElement.style.margin = "0 !important";
            pdfContent.insertBefore(breakPageElement, child);
              
            height = 0;
          } else {
            height += child.clientHeight;
          }
        }
      });

      // Clean duplicate page break nodes.
      document.querySelectorAll("section[slot='pdf-content']").forEach(pdfContent => {
        for (let i = 0; i < pdfContent.children.length - 1; i++) {
          const child     = pdfContent.children[i];
          const nextChild = pdfContent.children[i + 1];
          if (
            child.classList != undefined &&
            nextChild.classList != undefined &&
            child.classList.contains("html2pdf__page-break") &&
            nextChild.classList.contains("html2pdf__page-break")
          ) {
            pdfContent.removeChild(child);
          }
        }
      });

      // document.querySelectorAll("section[slot='pdf-content']").forEach(pdfContent => {
      //   console.log(pdfContent);
      // });
    }

    return {
      getSurveyStructureList,
      surveyStepList,
      messages_survey,
      surveyHeaderList,
      translateKey,
      translate,
      getHeader,
      print,
    };
  },
});
