
import { defineComponent, reactive, computed, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { InputTypes } from "@/store/enums/StoreEnums";
import { translate, translateKey } from "@/hooks/TranslateService";
import SurveyService from "@/core/services/SurveyService";

export default defineComponent({
  name: "step-1",
  props: ["input", "input_values_in_step", "isActiveStep"],
  components: {
    Field,
    ErrorMessage,
  },

  setup(props) {
    const empty = ref<string>("");

    const getWomenValue = computed({
      get() {
        let value = SurveyService.getSingleInputIntegerGenderField(
          props.input.input.set_id,
          props.input.input.id,
          InputTypes.INTEGER_WOMEN_SUB_ID,
          props.input_values_in_step
        ).input.value;
        return value;
      },
      set(val) {
        SurveyService.getSingleInputIntegerGenderField(
          props.input.input.set_id,
          props.input.input.id,
          InputTypes.INTEGER_WOMEN_SUB_ID,
          props.input_values_in_step
        ).input.value = val;
      },
    });

    const getMenValue = computed({
      get() {
        let value = SurveyService.getSingleInputIntegerGenderField(
          props.input.input.set_id,
          props.input.input.id,
          InputTypes.INTEGER_MEN_SUB_ID,
          props.input_values_in_step
        ).input.value;
        return value;
      },
      set(val) {
        SurveyService.getSingleInputIntegerGenderField(
          props.input.input.set_id,
          props.input.input.id,
          InputTypes.INTEGER_MEN_SUB_ID,
          props.input_values_in_step
        ).input.value = val;
      },
    });
    const total = reactive(
      computed(() => {
        const women_value = +SurveyService.getSingleInputIntegerGenderField(
          props.input.input.set_id,
          props.input.input.id,
          InputTypes.INTEGER_WOMEN_SUB_ID,
          props.input_values_in_step
        ).input.value;

        const men_value = +SurveyService.getSingleInputIntegerGenderField(
          props.input.input.set_id,
          props.input.input.id,
          InputTypes.INTEGER_MEN_SUB_ID,
          props.input_values_in_step
        ).input.value;

        const total_value = women_value + men_value;

        if (total_value > 0) {
          return total_value;
        }
        
        return props.input.kpi.optional ? "" : total_value;
      })
    );

    return {
      InputTypes,
      translate,
      translateKey,
      SurveyService,
      total,
      empty,
      getWomenValue,
      getMenValue,
    }; // anything returned here. will be available for the rest of the component
  },
});
