
import { defineComponent, reactive, computed, watch, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { InputTypes } from "@/store/enums/StoreEnums";
import { translate, translateKey } from "@/hooks/TranslateService";
import SurveyService from "@/core/services/SurveyService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "SelectYesNoField",
  props: ["input", "input_values_in_step", "isActiveStep"],
  components: {
    Field,
    ErrorMessage,
  },

  setup(props) {
    // const theInput = reactive(computed(() => props.input));
    const theInput = ref(props.input);
    const surveyInputList = reactive(
      computed(() => store.getters.getSurveyInputList)
    );
    watch(
      () => theInput.value.input.value,
      (value) => {
        if (props.input.kpi.auto_number == 34) {
          if (value == "NO") {
            const arr = surveyInputList.value.filter((item) => {
              if (
                item.input.set_id === 3 ||
                (item.input.set_id === 2 && item.input.id !== 1)
              )
                return false;
              return true;
            });
            store.dispatch(Actions.PUT_FILTERED_SURVEY_INPUT_LIST, arr);
          } else if (value == "YES") {
            store.dispatch(
              Actions.PUT_FILTERED_SURVEY_INPUT_LIST,
              surveyInputList.value
            );
          }
        }
      }
    );

    return {
      InputTypes,
      translate,
      translateKey,
      SurveyService,
      theInput,
    }; // anything returned here. will be available for the rest of the component
  },
});
